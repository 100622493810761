import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";
import Cookies from 'js-cookie';
import axios from 'axios'

const URLSERVER = process.env.REACT_APP_URL_SERVER;

export const Login = () => {
  const [code2fa, setcode2fa] = useState("");

  const handle2fa = () => {
    console.log("hello", code2fa)

    axios.post(`${URLSERVER}/sadmin/users/check_otp`, { "otp": code2fa })
      .then(res => {
        console.log(res)
        if (res.data.status === "success") {
          // alert("Login Success")
          console.log(res.data.token)
          Cookies.set('restyaboard', res.data.token, { domain: '.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space' });

          console.log("REACT_APP_MAIN:", process.env.REACT_APP_MAIN); // Ajoutez cette ligne pour vérifier la valeur
          // alert("ok")
          if (process.env.REACT_APP_MAIN === "local") {
            window.location.href = "http://localhost:3000";
          } else if (process.env.REACT_APP_MAIN === "test") {
            window.location.href = "https://trel29test05.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space";
          } else if (process.env.REACT_APP_MAIN === "prod") {
            window.location.href = "https://trelloe8d5f.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space";
          } else {
            window.location.href = "https://trel29test05.smdafdgnuxa0riuyptkvprx6cuyrlsxpjx0iktv5.space";
          }

        } else {
          alert("Invalid OTP")
        }
      })
      .catch(err => {
        console.log(err)
        alert("Invalid OTP")
      })
  }

  return (
    <Layout>
      <div className="container-fluid">
        <div
          className="col-12 col-lg-4 col-md-6 col-lg-offset-4 col-md-offset-4 col-sm-offset-3 mx-auto"
          style={{
            paddingRight: "15px",
            paddingLeft: "15px",
          }}
        >
          <div
            className="bg-white text-start"
            style={{
              overflow: "hidden",
              borderRadius: "12px",
              border: "1px solid #ddd",
            }}
          >
            <div
              style={{
                background: "#EAEDEF",
                padding: "10px 15px",
                fontSize: "21px",
                marginBottom: "20px",
                border: "1px solid #ddd",
              }}
            >
              <span
                className="lead card__title"
              >
                Login - Enter your 2FA CODE
              </span>
            </div>

            <div
              className="d-flex flex-column align-items-center"
              style={{
                fontSize: "14px",
                padding: "24px",
              }}
            >
              <input
                type="number"
                value={code2fa}
                onChange={(e) => setcode2fa(e.target.value)}
                placeholder="XXX XXX"
                className="w-100"
                style={{
                  padding: "6px 12px",
                  height: "34px",
                  color: "#555",
                  border: "1px solid #ccc",
                  borderRadius: "12px",
                  marginBottom: "15px"
                }}
              />

              <button
                type="submit"
                className="w-100 text-white"
                style={{
                  marginBottom: "15px",
                  borderRadius: "12px",
                  background: "#253858",
                  padding: "6px 12px",
                  border: "1px solid transparent"
                }}
                onClick={() => handle2fa()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
