import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../images/logoStars.png";

export const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <div className="bg-white container-fluid p-0 d-flex align-items-center justify-content-between flex-row nav-menu">
        <div className="d-md-none"></div>

        <Link
          to="/"
          style={{
            height: "24px",
            marginTop: "8px",
            marginBottom: "3px",
            paddingLeft: "1px",
          }}
          className="d-block"
        >
          <img src={logo} alt="logoIcon" />
        </Link>

        <div
          className="d-none d-md-flex align-items-center"
          style={{
            paddingRight: "16px",
            paddingBottom: "3px",
            fontSize: "15px",
          }}
        >
          {/* <button
            onClick={() => {
              navigate("/register");
            }}
            className="border-0 text-dark text-decoration-none fw-semibold"
            style={{
              borderRadius: "12px",
              background: location.pathname === "/register" ? "#eee" : "#FFF",
              marginRight: "1px",
              padding: "1px 8px",
            }}
          >
            Registration
          </button>

          <button
            className="d-flex border-0 align-items-center fw-semibold"
            onClick={() => {
              navigate("/login");
            }}
            style={{
              padding: "0 7px",
              borderRadius: "12px",
              background: location.pathname === "/login" || location.pathname === "/" ? "#eee" : "#FFF",
              height: "25px",
            }}
          >
            Login
          </button> */}
        </div>

        <button
          className="d-md-none d-flex flex-column menu-toggle"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <span className="sr-only"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>

      <div
        className="bg-white container-fluid d-flex d-md-none align-items-center justify-content-between flex-row"
        style={{
          height: openMenu ? "34px" : "0px",
          overflow: "hidden",
          transition: "all .3s ease",
          border: openMenu ? "1px solid #dedede" : "none",
          padding: "0px",
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            padding: "0 22px 0 15px",
            fontSize: "15px",
          }}
        >
          <button
            onClick={() => navigate("/register")}
            className="border-0 text-dark text-decoration-none fw-semibold"
            style={{
              borderRadius: "12px",
              background: location.pathname === "/register" ? "#eee" : "#FFF",
              marginRight: "1px",
              padding: "1px 8px",
            }}
          >
            Registration
          </button>

          <button
            className="d-flex border-0 align-items-center fw-semibold"
            onClick={() => navigate("/login")}
            style={{
              padding: "0 7px",
              borderRadius: "12px",
              background: location.pathname === "/login" || location.pathname === "/" ? "#eee" : "#FFF",
              height: "25px",
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};
