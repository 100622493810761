import "./App.css";
// import { ForgotPass } from "./pages/ForgotPass"
import { Login } from "./pages/Login";
// import { Register } from "./pages/Register";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotpassword" element={<ForgotPass />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
